<template>
  <div class="flex flex-col items-center justify-center p-5">
    <button class="text-mevcut-light w-12 h-12" @click="emit('refresh')">
      <slot>
        <v-icon icon="mdi mdi-alert-circle-outline" :size="48" />
      </slot>
    </button>

    <div class="mt-5 text-lg">{{ message }}</div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  message: string
  error?: Error
}>()

const emit = defineEmits(['refresh'])
</script>
